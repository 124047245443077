import { CSSTransition } from "react-transition-group";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import { getMediaType, readNumber } from "utils";
import Photo from "./photo";
import style from "./galleryModal.module.scss";
import { useEffect, useState } from "react";
import Audio from "./audio";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import ExtraBoxPortal from "./extraModal";

const GalleryModal = (props) => {
    const {
        list,
        paramKeyword
    } = props;

    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const initialSlide = readNumber(searchParams.get(paramKeyword));
    const [activeIndex, setActiveIndex] = useState(null);

    useEffect(() => {
        if (initialSlide !== null) {
            const newParams = createSearchParams(searchParams);
            newParams.delete(paramKeyword);
            setSearchParams(newParams, { replace: true });
        }
    }, [])

    const closeHandler = () => {
        navigate(-1);
    }

    return (
        <ExtraBoxPortal>
            <CSSTransition in={initialSlide !== null} timeout={300} unmountOnExit={true}>
                <div className="fixed top-0 left-0 w-full h-full anim-c9 bg-black/50 z-[101] flex flex-col">
                    <button className="absolute top-0 left-0 text-white/70 hover:text-white/80 text-2xl z-10 p-4" onClick={closeHandler}>
                        <i className="fa-solid fa-xmark" />
                    </button>

                    <div className="grow min-h-0">
                        <Swiper
                            spaceBetween={16}
                            slidesPerView={1}
                            modules={[Navigation]}
                            className="h-full"
                            initialSlide={initialSlide || 0}
                            loop
                            onSlideChange={e => {
                                setActiveIndex(e.realIndex);
                            }}
                            navigation={{
                                nextEl: `.${style.navBtn}.next`,
                                prevEl: `.${style.navBtn}.prev`
                            }}
                        >
                            {
                                list.map((media, i) => {
                                    const mediaType = getMediaType(media);

                                    return (
                                        <SwiperSlide key={`lightbox-media-${i}`}>
                                            {({ isActive }) => (
                                                <div className="w-full h-full p-4 flex flex-col items-center justify-center">
                                                    <div className="pos-cover" onClick={closeHandler} />

                                                    {
                                                        mediaType === "PHOTO" && <Photo url={media} />
                                                    }

                                                    {
                                                        mediaType === "AUDIO" && <Audio url={media} isActive={isActive} />
                                                    }
                                                </div>
                                            )}
                                        </SwiperSlide>
                                    )
                                })
                            }
                        </Swiper>
                    </div>

                    <div className="bg-black">
                        <div className="m-container flex justify-between items-center">
                            <button className={`${style.navBtn} next`}>
                                <i className="fa-regular fa-angle-right" />
                            </button>

                            <span className="text-sm text-white font-bold">
                                {`${activeIndex !== null ? activeIndex + 1 : "--"}/${list.length}`}
                            </span>

                            <button className={`${style.navBtn} prev`}>
                                <i className="fa-regular fa-angle-left" />
                            </button>
                        </div>
                    </div>
                </div>
            </CSSTransition>
        </ExtraBoxPortal>
    )
}

export default GalleryModal;