import Breadcrumb from "components/shared/breadcrumb";
import React, { memo, useContext } from "react"
import Order from "./rows/order";
import InitializeContext from "context/initialize-context";
import { checkPermission } from "utils";
import Content from "./rows/content";
import Comment from "./rows/comment";
import Ticket from "./rows/ticket";
import Financial from "./rows/financial/financial";
import User from "./rows/user";
import Subscription from "./rows/subscription";

const sections = [
    {
        permissionUrl: "/api/summary/financial",
        view: fetchUrl => <Financial fetchUrl={fetchUrl} />
    },
    {
        permissionUrl: "/api/summary/order",
        view: fetchUrl => <Order fetchUrl={fetchUrl} />
    },
    {
        permissionUrl: "/api/summary/subscription",
        view: fetchUrl => <Subscription fetchUrl={fetchUrl} />
    },
    {
        permissionUrl: "/api/summary/user",
        view: fetchUrl => <User fetchUrl={fetchUrl} />
    },
    {
        permissionUrl: "/api/summary/ticket",
        view: fetchUrl => <Ticket fetchUrl={fetchUrl} />
    },
    {
        permissionUrl: "/api/summary/content",
        view: fetchUrl => <Content fetchUrl={fetchUrl} />
    },
    {
        permissionUrl: "/api/summary/comment",
        view: fetchUrl => <Comment fetchUrl={fetchUrl} />
    }
]

const Dashboard = () => {
    const enums = useContext(InitializeContext);

    return (
        <div className="m-container dashboard">
            <div className="flex item-start md:items-center justify-start md:justify-between flex-col md:flex-row mb-6 options-bar">
                <div className="mb-6 md:mb-0">
                    <h1 className="title-c1 tp-21">داشبورد</h1>

                    <Breadcrumb data={[
                        { title: "داشبورد" }
                    ]} />
                </div>
            </div>

            {/* <div className="col-span-full xl:col-span-8">
                <div className="top-msg mb-4">
                    <div className="grid grid-cols-12 gap-4 w-full">
                        <div className="col-span-8">
                            <h2 className="font-bold text-white mb-4">خوش آمدید!</h2>
                            <p className="text-slate-300 text-xs">لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد</p>
                        </div>

                        <div className="col-span-4">
                            <img src="/assets/image/dashboard-vc.PNG" alt="dashboard vector" />
                        </div>
                    </div>
                </div>
            </div> */}

            <div className="mt-4">
                {
                    sections.map((sec, i) => (
                        <React.Fragment key={`section-${i}`}>
                            {
                                checkPermission([sec.permissionUrl], enums.permissions) ? sec.view(sec.permissionUrl) : <></>
                            }
                        </React.Fragment>
                    ))
                }
            </div>
        </div>
    )
}

export default memo(Dashboard);