import { memo, useEffect, useState } from "react";
import Breadcrumb from "components/shared/breadcrumb";
import { Link, useParams } from "react-router-dom";
import BatchRequest from "service/batch-request";
import UserData from "../common/user-data/user-data";
import Addresses from "./addresses/addresses";
import Orders from "../common/orders/orders";
import Ticket from "../common/ticket/ticket";
import Comments from "./comments/comments";
import Financial from "../common/financial/financial";
import LinksHolder from "../common/links-holder/links-holder";
import Profiles from "./profiles/list/profiles";
import Content from "../common/content/content";
import Subscriptions from "../common/subscriptions/subscriptions";

const User = () => {
    const { id } = useParams();

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [data, setData] = useState({});

    useEffect(() => {
        fetcher();
        // eslint-disable-next-line
    }, [])

    const fetcher = async () => {
        const routes = [
            {
                identifier: "userData",
                url: `/api/identity/user/get?id=${id}`,
                method: "GET"
            },
            {
                identifier: "addresses",
                url: `/api/address/all?id=${id}`,
                method: "GET"
            },
            {
                identifier: "summary",
                url: `/api/summary/by_user?id=${id}`,
                method: "GET"
            }
        ];

        try {
            const res = await BatchRequest({ routes, returnArray: true });

            const newData = {};
            res.forEach(routeRes => {
                if (routeRes.response.status_code === 200)
                    newData[routeRes.identifier] = routeRes.response.body;
                else
                    newData[routeRes.identifier] = { error: true };
            });

            setData(newData);
            setLoading(false);
        } catch {
            setError(true);
        }
    }

    return (
        <div className="m-container user-detail">
            <div className="flex item-start md:items-center justify-start md:justify-between flex-col md:flex-row mb-6 options-bar">
                <div className="mb-6 md:mb-0">
                    <h1 className="title-c1 tp-21">جزئیات کاربر</h1>

                    <Breadcrumb data={[
                        { title: "مدیریت کاربران" },
                        { title: "کاربران", link: "/panel/identity_user" },
                        { title: "جزئیات کاربر" }
                    ]} />
                </div>

                <div className="flex">
                    <Link to="/panel/identity_user" className="w-10 h-10 tp-22 text-[#424e79] dark:text-white rounded-md fit-center">
                        <i className="fa-regular fa-arrow-right"></i>
                    </Link>
                </div>
            </div>

            {
                !error ? (
                    <>
                        <div className="mb-4">
                            <div className="grid md:grid-cols-2 gap-4">
                                <UserData loading={loading} data={data.userData} userType="user" />
                                <Ticket loading={loading} data={data.summary} userType="user" />
                            </div>
                        </div>

                        <div className="mb-4">
                            <LinksHolder userType="user" />
                        </div>

                        <div className="mb-4">
                            <Addresses loading={loading} data={data.addresses} />
                        </div>

                        <div className="mb-4">
                            <Profiles loading={loading} data={{ data: data?.summary?.data?.profile?.profiles }} />
                        </div>

                        <div className="mb-4">
                            <Financial loading={loading} data={data.summary} userType="user" />
                        </div>

                        <div className="mb-4">
                            <Orders loading={loading} data={data.summary} userType="user" />
                        </div>

                        <div className="mb-4">
                            <Subscriptions loading={loading} data={data.summary} />
                        </div>

                        <div className="mb-4">
                            <Comments loading={loading} data={data.summary} />
                        </div>

                        <div className="mb-4">
                            <Content loading={loading} data={{ data: data?.summary?.data }} userType="user" />
                        </div>
                    </>
                ) : (
                    <div className="wrap-box tp-22 page-transition">
                        <p className="global-error">خطا در دریافت اطلاعات !</p>
                    </div>
                )
            }
        </div>
    )
}

export default memo(User);