const Photo = (props) => {
    const {
        url
    } = props;

    return (
        <img
            src={url}
            className="max-h-full max-w-full rounded-2xl relative"
        />
    )
}

export default Photo;