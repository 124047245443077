const subscriptionsEnum = [
    {
        id: 0,
        title: "غیرفعال"
    },
    {
        id: 1,
        title: "در انتظار پرداخت"
    },
    {
        id: 2,
        title: "پرداخت شده"
    },
    {
        id: 3,
        title: "فعال"
    },
    {
        id: 4,
        title: "پایان یافته"
    }
]

export default subscriptionsEnum;