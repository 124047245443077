import { useCallback, useContext, useEffect, useState } from "react";
import style from "../selectLocation.module.scss";
import searchAddressController from "../controllers/searchAddress";
import { ReducerContext } from "../reducerProvider";
import SearchCityBox from "./searchCityBox";

const Searchbar = () => {
    const { state, dispatch } = useContext(ReducerContext);

    const [inputValue, setInputValue] = useState("");
    const [timeoutState, setTimeoutState] = useState(null);
    const searchAddressState = state.searchAddress;

    useEffect(() => {
        if (timeoutState)
            clearTimeout(timeoutState);

        if (inputValue.length) {
            const newTimeout = setTimeout(() => {
                submitHandler();
            }, 2000);

            setTimeoutState(newTimeout);
        } else {
            setInputValue("");
        }

        // eslint-disable-next-line
    }, [inputValue])

    const submitHandler = (e) => {
        e?.preventDefault();

        if (e && searchAddressState.pending)
            return;

        if (timeoutState)
            clearTimeout(timeoutState);

        searchAddressController(state, dispatch, inputValue);
    }

    const flyToHandler = (x, y) => {
        dispatch({
            type: "SET_SHOW_RESULTS_BOX",
            payload: false
        })

        dispatch({
            type: "SET_FLY_TO",
            payload: [x, y]
        })

        dispatch({
            type: "SET_CENTER_POINT",
            payload: [x, y]
        })
    }

    const inputKeyDownHandler = e => {
        if (e.key === 'Enter' || e.keyCode === 13)
            submitHandler();
    }

    const cityBoxSelectHandler = useCallback((lat, lng) => {
        dispatch({
            type: "SET_FLY_TO",
            payload: [lat, lng]
        })
    }, [])

    return (
        <div className={style.searchbar}>
            <div className="flex">
                <SearchCityBox
                    selectHandler={cityBoxSelectHandler}
                />

                <div className="grow">
                    <div className="flex bg-white rounded-xl overflow-hidden">
                        <input
                            type="text"
                            className="py-4 px-2 bg-white grow min-w-0 text-xs"
                            placeholder="جستجوی آدرس ..."
                            value={inputValue}
                            onChange={e => setInputValue(e.target.value)}
                            onKeyDown={inputKeyDownHandler}
                        />

                        <button
                            type="button"
                            className="px-4 py-2 hover:bg-gray-50 text-sm"
                            onClick={submitHandler}
                        >
                            {
                                searchAddressState.pending ? (
                                    <i className="fa-regular fa-spinner-third spin relative top-px text-gray-400"></i>
                                ) : (
                                    <i className="fa-regular fa-magnifying-glass relative top-px text-gray-400"></i>
                                )
                            }
                        </button>
                    </div>
                </div>
            </div>

            {
                searchAddressState.showReslultBox && (
                    <div className={style.results}>
                        {
                            !searchAddressState.error ? (
                                searchAddressState.data.length ? (
                                    searchAddressState.data.map((result, i) => (
                                        <div
                                            className={style.result}
                                            key={`address-results-${i}`}
                                            onClick={() => flyToHandler(result.location.x, result.location.y)}
                                        >
                                            <span className="text-ellipsis grow overflow-hidden">
                                                <span className="block">{result.title}</span>

                                                {
                                                    result.address?.length > 0 && (
                                                        <span className="block whitespace-nowrap text-ellipsis overflow-hidden text-xs text-gray-400 dark:text-slate-600 mt-1">
                                                            {result.address}
                                                        </span>
                                                    )
                                                }
                                            </span>
                                        </div>
                                    ))
                                ) : (
                                    <p className="text-gray-400 p-4 text-sm font-bold tracking-tight">
                                        <i className="fa-regular fa-location-pin-slash ml-2"></i>
                                        نتیجه ای یافت نشد
                                    </p>
                                )
                            ) : <p className="text-red-500 text-xs font-bold p-4">خطا در دریافت اطلاعات !</p>
                        }
                    </div>
                )
            }
        </div>
    )
}

export default Searchbar;