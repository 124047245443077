import { memo, useCallback, useState } from "react";
import Modal from "components/shared/modal";
import SearchForm from "components/form-generator/search-form/search-form";
import { useLocation, useNavigate } from "react-router-dom";
import { urlDecoder, urlEncoder } from "utils";

const Searchbox = ({ fields }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);

    const [showModal, setShowModal] = useState(false);
    const [value, setValue] = useState(urlParams.get("key") ? urlDecoder(urlParams.get("key")) : "");

    const closeModal = useCallback(() => {
        setShowModal(false);
    }, [])

    const submitHandler = e => {
        e.preventDefault();

        if (value.length)
            urlParams.set("key", urlEncoder(value));
        else
            urlParams.delete("key");

        urlParams.delete("offset");
        navigate(`?${urlParams.toString()}`);
    }

    return (
        <>
            <div className="searchbox-c1">
                <form onSubmit={submitHandler}>
                    <div className="flex h-10 field">
                        <input
                            type="text"
                            className="tp-23 rounded-tr-md rounded-br-md text-sm px-4 w-44 focus:w-72 transition-all"
                            placeholder="جستجو ..."
                            value={value}
                            onChange={e => setValue(e.target.value)}
                        />

                        <button
                            type="button"
                            className="h-10 w-10 bg-primary-100 rounded-tl-md rounded-bl-md text-white fit-center hover:bg-primary-200 transition-colors"
                            onClick={() => setShowModal(true)}
                        >
                            <i className="fa-solid fa-filter"></i>
                        </button>
                    </div>
                </form>
            </div>

            <Modal show={showModal} title="فیلتر ها" closeHandler={closeModal} className="search-modal">
                <SearchForm fields={fields} doneCallback={closeModal} />
            </Modal>
        </>
    )
}

export default memo(Searchbox);   