import Breadcrumb from "components/shared/breadcrumb";
import GalleryModal from "components/shared/galleryModal/galleryModal";
import { productRoute } from "constants/config";
import UseFetch from "hooks/useFetch";
import { memo, useState } from "react";
import { createSearchParams, Link, useParams, useSearchParams } from "react-router-dom";
import TextareaAutosize from 'react-textarea-autosize';
import { toast } from "react-toastify";
import { CSSTransition } from "react-transition-group";
import MasterAPI from "service/master";
import { commentStatus, getProductImage, unixToShamsi } from "utils";

const Detail = () => {
    const { id } = useParams();
    const { isLoading, error, data } = UseFetch(`/api/comment/get?id=${id}`);
    const [searchParams, setSearchParams] = useSearchParams();
    const [submitLoading, setSubmitLoading] = useState(false);
    const [reply, setReply] = useState("");
    const [acceptLoading, setAcceptLoading] = useState(false);
    const [rejectLoading, setRejectLoading] = useState(false);
    const [forceStatus, setForceStatus] = useState({});

    const submitHandler = async () => {
        if (submitLoading)
            return;

        if (reply.length === 0) {
            toast.error("پاسخی وارد نشده است");
            return;
        }

        setSubmitLoading(true);
        try {
            const res = await MasterAPI({
                url: "/api/comment/reply",
                method: "PUT",
                payloadType: 3,
                data: {
                    comment_id: data.data.id,
                    text: reply
                },
                callback: () => setSubmitLoading(false)
            });

            setReply("");
            toast.success(res.message);
        } catch (e) {
            toast.error(e.message);
        }
    }

    const changeStatusHandler = async (status, replyId) => {
        if (acceptLoading || rejectLoading)
            return;

        if (status === 1)
            setAcceptLoading(true);
        else
            setRejectLoading(true);

        try {
            const res = await MasterAPI({
                url: "/api/comment/change_status_reply",
                payloadType: 3,
                method: "PUT",
                data: {
                    id,
                    reply_id: replyId,
                    status_id: status
                },
                callback: () => {
                    setAcceptLoading(false);
                    setRejectLoading(false);
                }
            });

            toast.success(res.message);
            setForceStatus(prev => {
                const newState = { ...prev };
                newState[replyId] = status;

                return newState;
            });
        } catch (e) {
            toast.error(e.message);
        }
    }

    const openAttachments = (intendedIndex) => {
        const newParams = createSearchParams(searchParams);
        newParams.set("gallery", `${intendedIndex}`);
        setSearchParams(newParams);
    }

    return (
        <>
            <div className="flex item-start md:items-center justify-start md:justify-between flex-col md:flex-row mb-6 options-bar">
                <div className="mb-6 md:mb-0">
                    <h1 className="title-c1 tp-21">جزئیات نظر</h1>
                    <Breadcrumb data={[
                        { title: "تعاملات کاربران" },
                        { title: "نظرات", link: "/panel/comment" },
                        { title: "جزئیات نظر" }
                    ]} />
                </div>

                <div className="flex">
                    <Link to="/panel/comment" className="w-10 h-10 tp-22 text-[#424e79] dark:text-white ml-3 rounded-md fit-center">
                        <i className="fa-regular fa-arrow-right"></i>
                    </Link>

                    <button
                        className={`bg-primary-100 hover:bg-primary-200 px-4 transition-colors text-white rounded-md fit-center text-sm h-10 relative overflow-hidden${submitLoading ? " cursor-progress" : ""}`}
                        onClick={submitHandler}
                    >
                        <i className="fa-light fa-pen-to-square ml-2"></i>
                        ثبت پاسخ

                        <CSSTransition in={submitLoading} timeout={200} unmountOnExit={true}>
                            <span className="pos-cover bg-primary-200 fit-center anim-c4">
                                <i className="fa-solid fa-spinner spin"></i>
                            </span>
                        </CSSTransition>
                    </button>
                </div>
            </div>

            <div className="wrap-box tp-22 page-transition">
                {
                    !isLoading ? (
                        !error ? (
                            <>
                                <div className="bg-yellow-100 flex items-center mb-5 rounded-md p-3 dark:bg-slate-800">
                                    <i className="fa-regular fa-circle-exclamation text-sm text-green-600 dark:text-green-300 ml-3"></i>

                                    <p className="text-sm text-green-800 dark:text-slate-300">
                                        <b className="inline-block ml-2">وضعیت نظر</b>
                                        <span>{commentStatus(data.data.status_id).title}</span>
                                    </p>
                                </div>

                                <div className="border-b border-gray-200 dark:border-slate-700 py-4">
                                    <ul className="order-detail">
                                        <li className="inline">
                                            <span>کاربر</span>
                                            <Link to={`/panel/user-detail/${data.data.user_id}`} className="font-medium text-blue-800 dark:text-blue-300">
                                                {data.data.name}
                                            </Link>
                                        </li>
                                    </ul>
                                </div>

                                <div className="border-b border-gray-200 dark:border-slate-700 py-4">
                                    <ul className="order-detail">
                                        <li className="inline">
                                            <span>تاریخ ثبت</span>
                                            <b>{unixToShamsi(data.data.created_at)}</b>
                                        </li>

                                        <li className="inline">
                                            <a href={`${productRoute}/${data.data.content_id}`} target="_blank" className="text-blue-400 dark:text-slate-400" rel="noreferrer">
                                                <i className="fa-regular fa-share-from-square ml-2"></i>
                                                رفتن به محصول
                                            </a>
                                        </li>
                                    </ul>
                                </div>

                                <div className="border-b border-gray-200 dark:border-slate-700 py-4">
                                    <ul className="order-detail">
                                        <li>
                                            <span className="block mb-2">متن نظر</span>
                                            <p className="whitespace-pre-line text-slate-900 dark:text-slate-100">{data.data.text}</p>
                                        </li>
                                    </ul>

                                    {
                                        data.data.attachments?.length > 0 && (
                                            <>
                                                <div className="flex items-center flex-wrap gap-2 mt-4">
                                                    {
                                                        data.data.attachments.map((attachment, i) => (
                                                            <img
                                                                key={`attachment-${i}`}
                                                                src={getProductImage(attachment)}
                                                                className="w-14 h-14 object-cover rounded-md cursor-pointer"
                                                                onClick={() => openAttachments(i)}
                                                            />
                                                        ))
                                                    }
                                                </div>

                                                <GalleryModal
                                                    list={data.data.attachments.map((attachment) => getProductImage(attachment))}
                                                    paramKeyword="gallery"
                                                />
                                            </>
                                        )
                                    }
                                </div>

                                {
                                    data.data.replies?.length && (
                                        <div className="border-b border-gray-200 dark:border-slate-700 py-4">
                                            <ul className="order-detail">
                                                {
                                                    data.data.replies.map((reply, i) => {
                                                        const thisStatus = forceStatus[reply.id] || reply.status_id;
                                                        const userLink = reply.user_kind === 2 ? `/panel/provider-detail/${reply.user_id}` : `/panel/identity_admin?id=${reply.user_id}`

                                                        return (
                                                            <li className="block no-circle mb-3" key={`reply-${i}`}>
                                                                <ul className="order-detail">
                                                                    <li className="inline">
                                                                        <span className="block mb-1">
                                                                            پاسخ {reply.user_kind === 2 ? "فروشنده" : "مدیر"} (<Link to={userLink} className="link-c1">{reply.name}</Link>)
                                                                        </span>
                                                                    </li>

                                                                    <li className="inline">
                                                                        <span className="block mb-1">{unixToShamsi(reply.created_at)}</span>
                                                                    </li>

                                                                    <li className="inline">
                                                                        <span className="block mb-1">{commentStatus(thisStatus).title}</span>

                                                                        {
                                                                            thisStatus !== 1 && (
                                                                                <button
                                                                                    className="bg-green-500 rounded-md text-white text-xs py-1 px-3 ml-1 relative overflow-hidden"
                                                                                    onClick={() => changeStatusHandler(1, reply.id)}
                                                                                >
                                                                                    تایید کردن

                                                                                    <CSSTransition in={acceptLoading} unmountOnExit={true} timeout={200}>
                                                                                        <span className="bg-green-500 anim-c4 fit-center pos-cover force-white">
                                                                                            <i className="fa-solid fa-spinner spin"></i>
                                                                                        </span>
                                                                                    </CSSTransition>
                                                                                </button>
                                                                            )
                                                                        }

                                                                        {
                                                                            thisStatus !== 2 && (
                                                                                <button
                                                                                    className="bg-red-500 rounded-md text-white text-xs py-1 px-3 relative overflow-hidden"
                                                                                    onClick={() => changeStatusHandler(2, reply.id)}
                                                                                >
                                                                                    رد کردن

                                                                                    <CSSTransition in={rejectLoading} unmountOnExit={true} timeout={200}>
                                                                                        <span className="bg-red-500 anim-c4 fit-center pos-cover force-white">
                                                                                            <i className="fa-solid fa-spinner spin"></i>
                                                                                        </span>
                                                                                    </CSSTransition>
                                                                                </button>
                                                                            )
                                                                        }
                                                                    </li>
                                                                </ul>

                                                                <p className="whitespace-pre-line text-slate-900 dark:text-slate-100">{reply.text}</p>
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    )
                                }

                                <div className="py-4">
                                    <span className="text-sm text-[#979797]">پاسخ {data.data.replies?.length ? "(پاسخ شما قبلا ثبت شده است و نیاز به پاسخ مجدد نمی باشد)" : ""}</span>

                                    <TextareaAutosize
                                        placeholder="وارد نمایید ..."
                                        className="reply-textarea"
                                        minRows={3}
                                        value={reply}
                                        onChange={e => setReply(e.target.value)}
                                    />
                                </div>
                            </>
                        ) : (
                            <></> // error
                        )
                    ) : (
                        <></> // loading
                    )
                }
            </div>
        </>
    )
}

export default memo(Detail);