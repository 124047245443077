import ExtraBoxPortal from "components/shared/extraBoxPortal";
import InitializeContext from "context/initialize-context";
import { useContext, useMemo, useState } from "react";
import { CSSTransition } from "react-transition-group";

const SearchCityBox = props => {
    const {
        selectHandler = () => { }
    } = props;

    const [showBox, setShowBox] = useState(false);
    const [selectedTitle, setSelectedTitle] = useState("تهران");
    const [inputVal, setInputVal] = useState("");
    const enums = useContext(InitializeContext);

    const cityItems = useMemo(() => {
        return enums.data.enumerationLocations.filter(enumEl => enumEl.parent_id === 1);
    }, [])

    const filteredCities = useMemo(() => {
        if (inputVal !== "") {
            return cityItems.filter((cityEl) => cityEl.title.indexOf(inputVal) !== -1);
        }

        return cityItems;
    }, [inputVal])

    const localSelectHandler = (title, lat, lng) => {
        if (lat && lng) {
            selectHandler(lat, lng);
            setShowBox(false);
            setSelectedTitle(title);
        }
    }

    if (cityItems.length === 0)
        return <></>

    return (
        <>
            <button
                className="flex items-center bg-white rounded-xl text-xs ml-2 px-3 text-right shadow-[0_0_5px_rgba(0,0,0,.1)]"
                onClick={() => setShowBox(true)}
                title={selectedTitle}
            >
                <span className="grow w-16 fit-content font-bold">
                    {selectedTitle}
                </span>

                <i className="fa-regular fa-angle-down mr-1" />
            </button>

            <CSSTransition in={showBox} unmountOnExit={true} timeout={0}>
                <ExtraBoxPortal selector="#extra-box">
                    <div className="fixed top-0 left-0 w-full h-full bg-black/40 z-[200] p-8 flex flex-col justify-center items-center">
                        <div className="pos-cover" onClick={() => setShowBox(false)} />

                        <div className="bg-white w-full max-w-2xl h-full max-h-72 rounded-xl shadow-2xl p-4 flex flex-col relative page-transition">
                            <div className="relative">
                                <input
                                    type="text"
                                    className="text-sm tracking-tighter w-full p-3 bg-gray-50 rounded-lg border focus:bg-white"
                                    placeholder="جستجو ..."
                                    value={inputVal}
                                    onChange={e => setInputVal(e.target.value)}
                                />

                                {
                                    inputVal !== "" && (
                                        <button
                                            className="absolute left-0 top-0 h-full fit-center px-4"
                                            onClick={() => setInputVal("")}
                                        >
                                            <i className="fa-regular fa-xmark" />
                                        </button>
                                    )
                                }
                            </div>

                            <ul className="grow overflow-auto mt-4 spec-sb no-select">
                                {
                                    filteredCities.map((city) => (
                                        <li
                                            key={`city-${city.id}`}
                                            className="city-item"
                                            onClick={() => localSelectHandler(city.title, city.point[0], city.point[1])}
                                        >
                                            {city.title}
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    </div>
                </ExtraBoxPortal>
            </CSSTransition>
        </>
    )
}

export default SearchCityBox;