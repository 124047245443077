import { memo, useMemo } from "react";
import moment from "jalali-moment";
import ChartBox from "components/skeleton/chart-box";
import ChartLine from "components/shared/charts/line";
import GoToAll from "components/shared/go-to-all";
import { useParams } from "react-router-dom";
import subscriptionsEnum from "constants/subscriptions";

const Subscriptions = props => {
    const { loading, data } = props;
    const intendedEnum = subscriptionsEnum;
    const { id } = useParams();

    const chartData = useMemo(() => {
        const counts = [];
        const prices = [];
        const days = [];

        if (!loading && !data.error) {
            data.data.subscription.graph_last_month.forEach(el => {
                counts.push(el.count);
                prices.push(el.price);
                days.push(moment.unix(el.day).format("jYYYY/jMM/jD"));
            })
        }

        return {
            counts,
            prices,
            days
        }
    }, [loading, data])

    return (
        <div className="grid grid-cols-12 gap-4 mb-4">
            <div className="col-span-full lg:col-span-8">
                {
                    !loading ? (
                        !data.error ? (
                            <div className="wrap-box tp-22 page-transition">
                                <h2 className="tp-34 text-sm font-bold pb-4">اشتراک های کاربر</h2>

                                <ChartLine
                                    series={[
                                        {
                                            name: 'تعداد سفارش',
                                            data: chartData.counts
                                        }, {
                                            name: 'مبلغ سفارش',
                                            data: chartData.prices
                                        }
                                    ]}
                                    xaxis={chartData.days}
                                />
                            </div>
                        ) : (
                            <div className="wrap-box tp-22 page-transition">
                                <p className="global-error">خطا در دریافت اطلاعات !</p>
                            </div>
                        )
                    ) : <ChartBox selectBox={false} />
                }
            </div>

            <div className="col-span-full lg:col-span-4">
                {
                    !loading ? (
                        !data.error ? (
                            <div className="wrap-box tp-22 page-transition">
                                <div className="state-box">
                                    <div className="flex justify-between items-center pb-4 reset-i">
                                        <h2 className="tp-34 text-sm font-bold">وضعیت سفارشات</h2>
                                        <GoToAll link={`/panel/subscriptions?user_id=${id}`} />
                                    </div>

                                    <ul>
                                        {
                                            intendedEnum.map(status => (
                                                <li key={`order-status-${status.id}`}>
                                                    <div>
                                                        <span className="sec-title tp-34">{status.title}</span>
                                                    </div>

                                                    <b className="tp-34">
                                                        {data.data.subscription.status[status.id] || 0}
                                                    </b>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </div>
                            </div>
                        ) : (
                            <div className="wrap-box tp-22 page-transition">
                                <p className="global-error">خطا در دریافت اطلاعات !</p>
                            </div>
                        )
                    ) : <ChartBox selectBox={false} />
                }
            </div>
        </div>
    )
}

export default memo(Subscriptions);