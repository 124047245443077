import UseFetch from "hooks/useFetch";
import { memo, useMemo } from "react"
import ChartLine from "components/shared/charts/line";
import moment from "jalali-moment";
import ChartBox from "components/skeleton/chart-box";
import TinyListSkeleton from "components/skeleton/tiny-list";
import GoToAll from "components/shared/go-to-all";
import subscriptionsEnum from "constants/subscriptions";

const Subscription = props => {
    const { fetchUrl } = props;
    const { data, isLoading, error } = UseFetch(fetchUrl);

    const chartData = useMemo(() => {
        const counts = [];
        const prices = [];
        const days = [];

        if (!isLoading && !error)
            data.data.subscription_graph_last_month.forEach(el => {
                counts.push(el.count);
                prices.push(el.price);
                days.push(moment.unix(el.day).format("jYYYY/jMM/jD"));
            })

        return {
            counts,
            prices,
            days
        }
    }, [data, error, isLoading])

    return (
        <div className="grid grid-cols-12 gap-4 mb-4">
            <div className="col-span-full lg:col-span-8">
                {
                    !isLoading ? (
                        !error ? (
                            <div className="wrap-box tp-22 page-transition">
                                <h2 className="title tp-34 mb-5">نمودار اشتراک ها</h2>
                                <ChartLine
                                    series={[
                                        {
                                            name: 'تعداد اشتراک',
                                            data: chartData.counts
                                        }, {
                                            name: 'مبلغ اشتراک',
                                            data: chartData.prices
                                        }
                                    ]}
                                    xaxis={chartData.days}
                                />
                            </div>
                        ) : <p className="text-red-500 text-xs">خطای سیستم!</p>
                    ) : <ChartBox selectBox={false} />
                }
            </div>

            <div className="col-span-full lg:col-span-4">
                <div className="w-full h-full relative">
                    <div className="relative lg:absolute top-0 right-0 w-full h-full">
                        {
                            !isLoading ? (
                                !error ? (
                                    <div className="wrap-box tp-22 page-transition h-full">
                                        <div className="state-box h-full flex flex-col">
                                            <div className="flex mb-4 reset-i justify-between">
                                                <h2 className="title tp-34">وضعیت اشتراک ها</h2>
                                                <GoToAll link="/panel/subscriptions" />
                                            </div>

                                            <div className="overflow-auto spec-sb-c2 pl-4">
                                                <ul>
                                                    {
                                                        subscriptionsEnum.map(status => (
                                                            <li key={`order-status-${status.id}`}>
                                                                <div>
                                                                    <span className="sec-title tp-34">{status.title}</span>
                                                                </div>

                                                                <b className="tp-34">
                                                                    {data.data.subscription_status[status.id] || 0}
                                                                </b>
                                                            </li>
                                                        ))
                                                    }

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                ) : <p className="text-red-500 text-xs">خطای سیستم!</p>
                            ) : <TinyListSkeleton fullHeight={true} />
                        }
                    </div>
                </div>
            </div>
        </div >
    )
}

export default memo(Subscription);