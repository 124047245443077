export const appVersion = window.APP_VERSION;
export const productRoute = window.PRODUCT_ROUTE;
export const productFormId = window.PRODUCT_FORM_ID;
export const vendorFormId = window.PROVIDER_FORM_ID;
export const smallCropSize = window.SMALL_CROP_SIZE;
export const siteTitle = window.SITE_TITLE;
export const userKind = window.USER_KIND;
export const skipRoutes = window.SKIP_ROUTES || [];
export const isSingleProvirderMode = window.IS_SINGLE_PROVIDER_MODE;
export const routesTitle = window.ROUTES_TITLE || {};
export const showProvinceAndCityInOrderDetail = window.SHOW_PROVINCE_AND_CITY_IN_ORDER_DETAIL || false;