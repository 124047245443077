import style from "./addressCard.module.scss";
import EditBtn from "./editBtn";

const AddressCard = (props) => {
    const {
        address,
        forView = false,
        editHandler,
        canEdit = true,
        resetHandler
    } = props;

    const selectedAddress = false;
    const thisSelected = !forView && selectedAddress.data.id === address.id;

    return (
        <div className="bg-[#F6F6F6] dark:bg-slate-800 dark:text-white p-2 rounded-xl text-sm relative mb-8">
            <div className={`${thisSelected ? "bg-[#F1E9DF] rounded-lg border border-[#C26F14]" : ""}  p-4 transition-all`}>
                <div className="flex items-start mb-2">
                    <div className="flex items-center grow min-w-0" />

                    {
                        canEdit && <EditBtn address={address} editHandler={editHandler} />
                    }

                    {
                        !!resetHandler && (
                            <button
                                className="text-red-500 hover:text-red-600 transition-colors mr-2"
                                onClick={resetHandler}
                                type="button"
                            >
                                <i className="fa-solid fa-trash-list"></i>
                            </button>
                        )
                    }
                </div>

                <div className={style.detailItem}>
                    <div className={style.icnBox}>
                        <i className="fa-solid fa-message-lines" />
                    </div>

                    <div className="grow">
                        <b className={style.title}>آدرس:</b>
                        <p className={`${style.val} text-gray-600 dark:text-gray-100`}>{
                            address.address + "" + (address.number ? "، پلاک: " + address.number : "") + (address.unit ? "، واحد: " + address.unit : "")
                        }</p>
                    </div>
                </div>

                {
                    address.postal_code?.length > 0 && (
                        <div className={style.detailItem}>
                            <div className={style.icnBox}>
                                <i className="fa-solid fa-envelope" />
                            </div>

                            <div className="grow">
                                <b className={style.title}>کد پستی:</b>
                                <p className={`${style.val} text-gray-600 dark:text-gray-100`}>{address.postal_code}</p>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default AddressCard;