import { useEffect, useRef } from "react";

const Audio = (props) => {
    const { url, isActive } = props;
    const ref = useRef(null);

    useEffect(() => {
        if (ref) {
            if (isActive)
                ref.current.play();
            else
                ref.current.pause();
        }
    }, [isActive, ref])

    return (
        <audio controls ref={ref} className="w-full max-w-sm swiper-no-swiping">
            <source src={url} type="audio/mpeg" />
        </audio>
    )
}

export default Audio;