import TextareaAutosize from 'react-textarea-autosize';
import Modal from "components/shared/modal";
import { memo, useCallback, useState } from "react"

const patternMessage = (newMessage, requreKeys, keys) => {
    try {
        let message = newMessage;
        requreKeys.forEach(key => {
            if (!message.includes(`{${key}}`)) {
                throw new Error(`کلید {${key}} در متن وجود ندارد`)
            }
        })

        Object.keys(keys).forEach(key => {
            message = message.replaceAll(`{${key}}`, keys[key])
        })

        return message;
    } catch (error) {
        return <p className="global-error">خطا در تبدیل مقدار: {error.message}</p>
    }
}

const MessagePattern = props => {
    const [showPropertyModal, setShowPropertyModal] = useState(false);

    const closeModal = useCallback(() => {
        setShowPropertyModal(false);
    }, [])

    if (!props.value.new_message) {
        props.value.new_message = props.value.original_message;
        props.changeHandler({ "new_message": props.value.new_message }, props.id)
    }

    return (
        <div className="grid grid-cols-1 lg:grid-cols-12">
            {
                props.value.require_keys.length ? (
                    <div className="col-span-1 lg:col-span-12 mb-2">
                        <div className="flex flex-col lg:flex-row items-center">
                            <div>
                                <button
                                    className="text-xs rounded-full py-1 px-2 bg-slate-200 dark:bg-slate-800 text-slate-600 dark:text-slate-200 ml-1 inline-block"
                                    onClick={() => setShowPropertyModal(true)}
                                >
                                    مثال مقادیر کلید ها
                                </button>
                            </div>
                            <div >
                                کلید های ضروری: {
                                    props.value.require_keys.map((key, i) => (
                                        <span
                                            key={`required-key-${i}`}
                                            className="rounded-md py-1 px-2 bg-slate-100 dark:bg-slate-700 ml-1"
                                        >
                                            {`{${key}}`}
                                        </span>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="col-span-1 lg:col-span-12 mb-2">
                        <div className="flex flex-col lg:flex-row items-center">
                            <div>
                                <button
                                    className="text-xs rounded-full py-1 px-2 bg-slate-200 dark:bg-slate-800 text-slate-600 dark:text-slate-200 ml-1 inline-block"
                                    onClick={() => setShowPropertyModal(true)}
                                >
                                    مثال مقادیر کلید ها
                                </button>
                            </div>
                        </div>
                    </div>
                )
            }
            <div className="col-span-6 lg:col-span-12">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-4">
                    <TextareaAutosize
                        placeholder="وارد نمایید ..."
                        className="reply-textarea"
                        minRows={3}
                        value={props.value.new_message}
                        onChange={e => props.changeHandler({ "new_message": e.target.value }, props.id)}
                    />
                    <div className="grid grid-cols-1">
                        <div className="w-full border-b-4 rounded p-2">
                            مقدار اصلی:
                            <div>
                                <pre>{props.value.original_message}</pre>
                            </div>
                        </div>
                        <div className="w-full p-2">
                            <div>
                                مقدار جدید:
                                <pre>{patternMessage(props.value.new_message, props.value.require_keys, props.value.keys)}</pre>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={showPropertyModal} title="مثال مقادیر کلید ها" closeHandler={closeModal}>
                {
                    <table className="w-full text-sm text-gray-800 dark:text-slate-200">
                        <thead>
                            <tr>
                                <th className="w-32" />
                                <th />
                            </tr>
                        </thead>

                        <tbody className="[&>*:nth-child(even)]:bg-slate-50 dark:[&>*:nth-child(even)]:bg-slate-800">
                            {
                                Object.keys(props.value.keys).length ? (
                                    Object.keys(props.value.keys).map((key, i) => (
                                        <tr key={`property-${i}`}>
                                            <td className="p-3 font-medium">{`{${key}}`}</td>
                                            <td className="p-3">
                                                {
                                                    <span
                                                        key={`property-${i}`}
                                                        className="rounded-md py-1 px-2 bg-slate-100 dark:bg-slate-700 ml-1"
                                                    >
                                                        {props.value.keys[key]}
                                                    </span>
                                                }
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan={2}>
                                            <p className="global-error mb-1">مشخصه ای برای نمایش وجود ندارد</p>
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                }
            </Modal>
        </div>
    )
}

export default memo(MessagePattern);