import Dashboard from "components/dashboard/dashboard";
import TableGrid from "components/table-grid/table-grid";
import Content from "components/content/content";
import Control from "components/control/control";
import PageCode from "components/page-code/page-code";
import PageTemplateCode from "components/page-template-code/page-template-code";
import Ticket from "components/ticket/ticket";
import OrderDetail from "components/order/order";
import Price from "components/price/price";
import Transactions from "components/transactions/transactions";
import Comment from "components/comment/comment";
import Message from "components/message/message";
import UserProfile from "components/user-profile/user-profile";
import { productFormId, vendorFormId } from "./config";
import Wallet from "components/wallet/wallet";
import AddWallet from "components/wallet/add/add";
import ProviderLogs from "components/provider-logs/logs";
import AdminLogs from "components/admin-logs/logs";
import UserLogs from "components/user-logs/logs";
import AppLogs from "components/app-logs/app-logs";
import Permissions from "components/permissions/permissions";
import FileManager from "components/file-manager/file-manager";
import Checkout from "components/checkout/checkout";
import InternetReceipt from "components/internet-receipt/internet-receipt";
import Basket from "components/basket/basket";
import UpdateScore from "components/update-score/update-score";
import Score from "components/score/score";
import RoutesList from "components/routes-list/routes-list";
import CropSizes from "components/crop-sizes/crop-sizes";
import UploadExtensions from "components/upload-extensions/upload-extensions";
import EditSetting from "components/routes-list/edit-setting";
import UserDetail from "components/identity-detail/user/user";
import ProviderDetail from "components/identity-detail/provider/provider";
import ProviderProfileDetail from "components/provider-profile-detail/provider-profile-detail";
import UpdateApps from "components/routes-list/update-apps";
import Tokens from "components/tokens/tokens";
import Duplicate from "components/duplicate/duplicate";
import PasswordSetting from "components/password-setting/password-setting";
import Remittance from "components/remittance/remittance";
import Subscriptions from "components/subscriptions/subscriptions";
import FileIdentity from "components/file-identity/file-identity";
import GeneralKey from "components/general-key/general-key";
import PaylineProxy from "components/payline-proxy/payline-proxy";
import OnlyForm from "components/only-form/only-form";
import UpdateProfiles from "components/identity-detail/provider/profiles/update/update-profiles";
import UpdateUserProfiles from "components/identity-detail/user/profiles/update/update-profiles";
import UserProfileDetail from "components/user-profile-detail/user-profile-detail";
import DemoAudioDuration from "components/demoAudioDuration/demoAudioDuration";
import Thumbnails from "components/thumbnails/thumbnails";
import NotificationSetting from "components/notification-setting/notification-setting";

const routes = [
    {
        id: 1,
        title: "پیشخوان",
        icon: "fa-house",
        view: <Dashboard />,
        index: true,
        route: "/panel"
    },
    {
        id: 2,
        title: "مدیریت اپلیکیشن",
        icon: "fa-cart-shopping",
        childs: [
            {
                id: "order",
                title: "سفارشات",
                route: "/panel/order",
                params: [":id"],
                view: <OrderDetail template="order" key="order" />,
                permissionUrl: [
                    "/api/v2/financial/order/cancel",
                    "/api/v2/financial/order/change",
                    "/api/v2/financial/order/get",
                    "/api/v2/financial/order/list",
                    "/api/v2/financial/order/group/change_status",
                    "/api/v2/financial/order/change_status"
                ]
            },
            {
                id: "content",
                route: "/panel/content",
                title: "فهرست محصولات",
                view: <Content template="content" key="content" />,
                params: [":id", "add/:addId"],
                permissionUrl: [
                    "/api/content/create",
                    "/api/content/get",
                    "/api/content/edit",
                    "/api/content/change_status",
                    "/api/content/list",
                ]
            },
            {
                id: "content",
                route: "/panel/duplicate",
                title: "قالب محتوا",
                view: <Duplicate template="product" key="product" />,
                params: [":id"],
                extendRoute: "?form_id=5",
                show: false,
                permissionUrl: ["/api/content/list"]
            },
            {
                id: "price",
                title: "لیست قیمت",
                route: "/panel/price",
                view: <Price template="price" />,
                permissionUrl: [
                    "/api/content/edit_module_fields_multiple",
                    "/api/content/list",
                ],
                extendRoute: `?form_id=${productFormId}`,
            },
            {
                title: "مدیریت تخفیف",
                show: false
            },
            {
                id: "sellers",
                route: "/panel/sellers",
                title: "مدیریت فروشندگان",
                view: <Content template="sellers" key="sellers" />,
                params: [":id", "add/:addId"],
                extendRoute: `?form_id=${vendorFormId}`,
                permissionUrl: [
                    "/api/content/create",
                    "/api/content/get",
                    "/api/content/edit",
                    "/api/content/change_status",
                    "/api/content/list",
                ]
            },
            {
                id: "basket",
                title: "سبد کاربران",
                route: "/panel/basket",
                view: <Basket />,
                permissionUrl: [
                    "/api/basket/current/get",
                    "/api/basket/current/list",
                    "/api/basket/next/get",
                    "/api/basket/next/list"
                ],
                params: [":id"]
            },
            {
                id: "subscriptions",
                title: "اشتراک",
                route: "/panel/subscriptions",
                view: <Subscriptions template="subscriptions" key="subscriptions" />,
                permissionUrl: [
                    "/api/financial/subscription/get",
                    "/api/financial/subscription/list"
                ],
                params: [":id"]
            }
        ]
    },
    {
        id: 3,
        title: "امور مالی",
        icon: "fa-wallet",
        childs: [
            {
                id: "transactions",
                title: "تراکنشها",
                route: "/panel/transactions",
                params: [":id"],
                view: <Transactions template="transactions" key="transactions" />,
                permissionUrl: [
                    "/api/financial/balance/history",
                    "/api/financial/balance/get",
                ]
            },
            {
                id: "wallet",
                title: "کیف پول",
                route: "/panel/wallet",
                params: [":id"],
                view: <Wallet template="wallet" key="wallet" />,
                permissionUrl: [
                    "/api/financial/balance/wallet/list",
                    "/api/financial/balance/wallet/get",
                    "/api/financial/balance/create",
                ]
            },
            {
                id: "add_wallet",
                title: "کیف پول",
                route: "/panel/add_wallet",
                params: [":userId"],
                view: <AddWallet />,
                show: false,
                force_select: "wallet"
            },
            {
                id: "internet_receipt",
                title: "رسیدهای اینترنتی",
                route: "/panel/internet-receipt",
                params: [":id"],
                view: <InternetReceipt template="internet_receipt" key="internet_receipt" />,
                permissionUrl: [
                    "/api/financial/balance/history",
                    "/api/financial/balance/get",
                ]
            },
            {
                id: "checkout",
                title: "تسویه حساب",
                route: "/panel/checkout",
                params: [":id"],
                view: <Checkout template="checkout" key="checkout" />,
                permissionUrl: [
                    "/api/financial/balance/withdraw",
                    "/api/financial/balance/withdraw/history",
                    "/api/financial/balance/withdraw/history/:id",
                    "/api/financial/balance/withdraw/reject",
                    "/api/financial/balance/withdraw/approve",
                ],
            },
            {
                id: "remittance",
                title: "واریز به حساب",
                route: "/panel/remittance",
                params: [":id"],
                view: <Remittance template="remittance" key="remittance" />,
                permissionUrl: [
                    "/api/financial/balance/remittance",
                    "/api/financial/balance/remittance/history",
                    "/api/financial/balance/remittance/history/:id",
                    "/api/financial/balance/remittance/approve",
                    "/api/financial/balance/remittance/reject"
                ]
            }
        ]
    },
    {
        id: 4,
        title: "مدیریت محتوا",
        icon: "fa-database",
        childs: [
            {
                id: "page",
                title: "مدیریت صفحات",
                route: "/panel/page",
                params: [":id"],
                view: <TableGrid template="page" key="page" />,
                permissionUrl: [
                    "/api/page/create",
                    "/api/page/get",
                    "/api/page/edit",
                    "/api/page/set",
                    "/api/page/list",
                    "/api/page/get_code",
                    "/api/page/edit_code",
                    "/api/page/get_control",
                    "/api/page/add_control",
                    "/api/page/edit_control",
                    "/api/page/priority_control",
                    "/api/page/template/list",
                ]
            },
            {
                id: "page_control",
                route: "/panel/page/control",
                view: <Control template="page_control" key="page_control" />,
                params: [":pageId/:mode", ":pageId/:mode/:id"],
                show: false,
                force_select: "page"
            },
            {
                id: "page_code",
                route: "/panel/page/code",
                view: <PageCode />,
                params: [":id"],
                show: false,
                force_select: "page"
            },
            {
                id: "category",
                title: "دسته بندی ها",
                route: "/panel/manageContent/categories",
                params: [":id", "yyyy/:name"],
                view: <TableGrid template="category" key="category" />,
                permissionUrl: [
                    "/api/category/create",
                    "/api/category/get",
                    "/api/category/edit",
                    "/api/category/set",
                    "/api/category/list",
                    "/api/category/list",
                    "/api/form/element/create",
                    "/api/form/element/get",
                    "/api/form/element/edit",
                    "/api/form/element/set",
                    "/api/form/element/list",
                ]
            },
            {
                id: "page_static",
                title: "صفحات استاتیک",
                route: "/panel/page/static",
                params: [":id"],
                view: <TableGrid template="page_static" key="page_static" />,
                permissionUrl: [
                    "/api/page/static/create",
                    "/api/page/static/get",
                    "/api/page/static/edit",
                    "/api/page/static/set",
                    "/api/page/static/list",
                ]
            },
            {
                id: "file_manager",
                title: "مدیریت فایلها",
                route: "/panel/file-manager",
                params: [":tab"],
                view: <FileManager />,
                permissionUrl: [
                    "/api/upload/static/file",
                    "/api/upload/static/file/move",
                    "/api/upload/static/file/list",
                    "/api/upload/static/text/get",
                    "/api/upload/static/text/edit",
                ]
            },
            {
                id: "file_identity",
                title: "مدیریت فایلهای آپلودی",
                route: "/panel/file_identity",
                params: [":id"],
                view: <FileIdentity template="file_identity" key="file_identity" />,
                permissionUrl: [
                    "/api/file_identity/get",
                    "/api/file_identity/list",
                ]
            },
            {
                id: "tag",
                title: "برچسب ها",
                route: "/panel/tag",
                params: [":id"],
                view: <TableGrid template="tag" key="tag" />,
                show: false
            }
        ]
    },
    {
        id: 5,
        title: "تعاملات کاربران",
        icon: "fa-envelope",
        childs: [
            {
                id: "comment",
                title: "نظرات کاربران",
                route: "/panel/comment",
                params: [":id"],
                view: <Comment template="comment" key="comment" />,
                permissionUrl: [
                    "/api/comment/get",
                    "/api/comment/list",
                    "/api/comment/change_status",
                    "/api/comment/change_status_reply",
                    "/api/comment/reply",
                ]
            },
            {
                id: "user_ticket",
                title: "تیکت کاربران",
                route: "/panel/userTicket",
                params: [":id"],
                view: <Ticket mode="user" template="user_ticket" key="user_ticket" />,
                permissionUrl: [
                    "/api/ticket/user/list",
                    "/api/ticket/user/get",
                    "/api/ticket/user/reply",
                    "/api/ticket/user/list_replies",
                    "/api/ticket/user/change_status",
                ]
            },
            {
                id: "provider_ticket",
                title: "تیکت تامین کنندگان",
                route: "/panel/providerTicket",
                params: [":id"],
                view: <Ticket mode="provider" template="provider_ticket" key="provider_ticket" />,
                permissionUrl: [
                    "/api/ticket/provider/list",
                    "/api/ticket/provider/get",
                    "/api/ticket/provider/reply",
                    "/api/ticket/provider/list_replies",
                    "/api/ticket/provider/change_status",
                ]
            },
            {
                id: "usersMessage",
                title: "پیام کاربران",
                route: "/panel/usersMessage",
                params: [":id"],
                view: <Message mode="user" template="usersMessage" key="usersMessage" />,
                permissionUrl: [
                    "/api/message/user/send",
                    "/api/message/user/list",
                    "/api/message/user/get",
                    "/api/message/user/change_status",
                ]
            },
            {
                id: "providersMessage",
                title: "پیام تامین کنندگان",
                route: "/panel/providersMessage",
                params: [":id"],
                view: <Message mode="provider" template="providersMessage" key="providersMessage" />,
                permissionUrl: [
                    "/api/message/provider/send",
                    "/api/message/provider/list",
                    "/api/message/provider/get",
                    "/api/message/provider/change_status",
                ]
            },
            {
                id: "notifications",
                title: "اطلاع رسانی",
                route: "/panel/notifications",
                view: <RoutesList template="notifications" key="notifications" />,
                permissionUrl: [
                    "/api/notification/send_message",
                    "/api/notification/send_sms"
                ]
            },
            {
                id: "send_sms",
                title: "ارسال پیامک",
                route: "/panel/notifications/send-sms",
                view: <OnlyForm template="send_sms" key="send_sms" />,
                show: false,
                force_select: "notifications"
            },
            {
                id: "send_notifications",
                title: "کلیدهای عمومی",
                route: "/panel/notifications/send-notifications",
                view: <OnlyForm template="send_notifications" key="send_notifications" />,
                show: false,
                force_select: "notifications"
            }
        ]
    },
    {
        id: 6,
        title: "مدیریت کاربران",
        icon: "fa-lock",
        childs: [
            {
                id: "identity_admin",
                title: "مدیران",
                route: "/panel/identity_admin",
                params: [":id"],
                view: <TableGrid template="identity_admin" key="identity_admin" />,
                permissionUrl: [
                    "/api/identity/admin/create",
                    "/api/identity/admin/get",
                    "/api/identity/admin/edit",
                    "/api/identity/admin/set",
                    "/api/identity/admin/list",
                    "/api/permission/admin/list",
                ]
            },
            {
                id: "tokens_admin",
                title: "توکن های مدیر",
                route: "/panel/tokens/admin",
                params: [":userId", ":userId/:id"],
                view: <Tokens template="tokens_admin" key="tokens_admin" />,
                force_select: "identity_admin",
                show: false
            },
            {
                id: "identity_provider",
                title: "تامین کنندگان",
                route: "/panel/identity_provider",
                params: [":id"],
                view: <TableGrid template="identity_provider" key="identity_provider" />,
                permissionUrl: [
                    "/api/identity/provider/create",
                    "/api/identity/provider/get",
                    "/api/identity/provider/edit",
                    "/api/identity/provider/set",
                    "/api/identity/provider/list",
                    "/api/identity/provider/get_with_profiles",
                    "/api/identity/provider/list_with_profiles",
                    "/api/permission/provider/list",
                    "/api/shop/setting/wage_type/list",
                ]
            },
            {
                id: "tokens_provider",
                title: "توکن های تامین کننده",
                route: "/panel/tokens/provider",
                params: [":userId", ":userId/:id"],
                view: <Tokens template="tokens_provider" key="tokens_provider" />,
                force_select: "identity_provider",
                show: false
            },
            {
                id: "identity_user_detail",
                title: "جزئیات تامین کننده",
                route: "/panel/provider-detail",
                params: [":id"],
                view: <ProviderDetail />,
                force_select: "identity_provider",
                show: false
            },
            {
                id: "update_provider_profiles",
                title: "بروزرسانی پروفایل های تامین کننده",
                route: "/panel/provider-profiles",
                params: [":id"],
                view: <UpdateProfiles />,
                force_select: "identity_provider",
                show: false
            },
            {
                id: "provider_profile_details",
                title: "جزئیات پروفایل تامین کننده",
                route: "/panel/provider-profile-detail",
                view: <ProviderProfileDetail />,
                force_select: "identity_provider",
                show: false
            },
            {
                id: "identity_user",
                title: "کاربران",
                route: "/panel/identity_user",
                params: [":id"],
                view: <TableGrid template="identity_user" key="identity_user" />,
                permissionUrl: [
                    "/api/identity/user/create",
                    "/api/identity/user/get",
                    "/api/identity/user/edit",
                    "/api/identity/user/set",
                    "/api/identity/user/list",
                    "/api/permission/user/list",
                ]
            },
            {
                id: "update_user_profiles",
                title: "بروزرسانی پروفایل های کاربر",
                route: "/panel/user-profiles",
                params: [":id"],
                view: <UpdateUserProfiles />,
                force_select: "identity_user",
                show: false
            },
            {
                id: "user_profile_details",
                title: "جزئیات پروفایل کاربر",
                route: "/panel/user-profile-detail",
                view: <UserProfileDetail />,
                force_select: "identity_user",
                show: false
            },
            {
                id: "tokens_user",
                title: "توکن های کاربر",
                route: "/panel/tokens/user",
                params: [":userId", ":userId/:id"],
                view: <Tokens template="tokens_user" key="tokens_user" />,
                force_select: "identity_user",
                show: false
            },
            {
                id: "identity_user_detail",
                title: "جزئیات کاربر",
                route: "/panel/user-detail",
                params: [":id"],
                view: <UserDetail />,
                force_select: "identity_user",
                show: false
            },
            {
                id: "update_score",
                title: "تغییر امتیاز",
                route: "/panel/update-score",
                params: [":id"],
                view: <UpdateScore />,
                permissionUrl: [],
                show: false,
                force_select: "identity_user"
            },
            {
                id: "admin_permissions",
                title: "نقش های ادمین",
                route: "/panel/permission/admin",
                view: <Permissions mode="admin" template="permission_admin" key="permission_admin" />,
                params: [":id"],
                permissionUrl: [
                    "/api/permission/admin/create",
                    "/api/permission/admin/get",
                    "/api/permission/admin/edit",
                    "/api/permission/admin/list",
                    "/api/permission/admin/change_status",
                    "/api/permission/admin/forms_group_policies/get",
                    "/api/permission/admin/forms_group_policies/edit",
                    "/api/permission/admin/routes/all",
                    "/api/permission/admin/routes/list",
                    "/api/permission/admin/routes/get",
                    "/api/permission/admin/routes/edit",
                ]
            },
            {
                id: "provider_permissions",
                title: "نقش های تامین کنندگان",
                route: "/panel/permission/provider",
                view: <Permissions mode="provider" template="permission_provider" key="permission_provider" />,
                params: [":id"],
                permissionUrl: [
                    "/api/permission/provider/create",
                    "/api/permission/provider/get",
                    "/api/permission/provider/edit",
                    "/api/permission/provider/list",
                    "/api/permission/provider/change_status",
                    "/api/permission/provider/forms_group_policies/get",
                    "/api/permission/provider/forms_group_policies/edit",
                    "/api/permission/provider/routes/all",
                    "/api/permission/provider/routes/list",
                    "/api/permission/provider/routes/get",
                    "/api/permission/provider/routes/edit",
                ]
            },
            {
                id: "user_permissions",
                title: "نقش های کاربران",
                route: "/panel/permission/user",
                view: <Permissions mode="user" template="permission_user" key="permission_user" />,
                params: [":id"],
                permissionUrl: [
                    "/api/permission/user/create",
                    "/api/permission/user/get",
                    "/api/permission/user/edit",
                    "/api/permission/user/list",
                    "/api/permission/user/change_status",
                    "/api/permission/user/forms_group_policies/get",
                    "/api/permission/user/forms_group_policies/edit",
                    "/api/permission/user/routes/all",
                    "/api/permission/user/routes/list",
                    "/api/permission/user/routes/get",
                    "/api/permission/user/routes/edit",
                ]
            }
        ]
    },
    {
        id: 7,
        title: "تنظیمات فروشگاه",
        icon: "fa-palette",
        childs: [
            // {
            //     id: "shop_setting_withdraw_type",
            //     title: "انواع تسویه حساب",
            //     route: "/panel/shop_setting/withdraw_type",
            //     params: [":id"],
            //     view: <TableGrid template="shop_setting_withdraw_type" key="shop_setting_withdraw_type" />,
            //     permissionUrl: [
            //         "/api/financial/withdraw/type/get",
            //         "/api/financial/withdraw/type/edit",
            //         "/api/financial/withdraw/type/edit",
            //         "/api/financial/withdraw/type/list",
            //     ]
            // },
            {
                id: "shop_setting_wage_type",
                title: "انواع کارمزد",
                route: "/panel/shop_setting/wage_type",
                params: [":id"],
                view: <TableGrid template="shop_setting_wage_type" key="shop_setting_wage_type" />,
                permissionUrl: [
                    "/api/shop/setting/wage_type/create",
                    "/api/shop/setting/wage_type/get",
                    "/api/shop/setting/wage_type/edit",
                    "/api/shop/setting/wage_type/set",
                    "/api/shop/setting/wage_type/list",
                ]
            },
            {
                id: "shop_setting_transport_type",
                title: "شیوه های ارسال",
                route: "/panel/shop_setting/transport_type",
                params: [":id"],
                view: <TableGrid template="shop_setting_transport_type" key="shop_setting_transport_type" />,
                permissionUrl: [
                    "/api/shop/setting/delivery_type/create",
                    "/api/shop/setting/delivery_type/get",
                    "/api/shop/setting/delivery_type/edit",
                    "/api/shop/setting/delivery_type/set",
                    "/api/shop/setting/delivery_type/list",
                ]
            },
            {
                id: "shop_setting_currency_type",
                title: "انواع نرخ های ارز",
                route: "/panel/shop_setting/currency",
                params: [":id"],
                view: <TableGrid template="shop_setting_currency_type" key="shop_setting_currency_type" />,
                permissionUrl: [
                    "/api/shop/setting/currency/create",
                    "/api/shop/setting/currency/get",
                    "/api/shop/setting/currency/edit",
                    "/api/shop/setting/currency/set",
                    "/api/shop/setting/currency/list",
                ]
            },
            {
                id: "income_type",
                title: "انواع مخزن درآمد",
                route: "/panel/shop_setting/income_type",
                params: [":id"],
                view: <TableGrid template="income_type" key="income_type" />,
                permissionUrl: [
                    "/api/financial/income_type/create",
                    "/api/financial/income_type/get",
                    "/api/financial/income_type/edit",
                    "/api/financial/income_type/set",
                    "/api/financial/income_type/list",
                ]
            },
            {
                id: "shop_setting_payment_type",
                title: "شیوه های پرداخت",
                route: "/panel/shop_setting/payment_type",
                params: [":id"],
                view: <TableGrid template="shop_setting_payment_type" key="shop_setting_payment_type" />,
                permissionUrl: [
                    "/api/financial/payment_type/get",
                    "/api/financial/payment_type/edit",
                    "/api/financial/payment_type/set",
                    "/api/financial/payment_type/list",
                ]
            },
            {
                id: "payline",
                title: "انواع درگاه های اینترنتی",
                route: "/panel/shop_setting/payline",
                params: [":id"],
                view: <TableGrid template="payline" key="payline" />,
                permissionUrl: [
                    "/api/financial/payline/get",
                    "/api/financial/payline/edit",
                    "/api/financial/payline/set",
                    "/api/financial/payline/list",
                ]
            },
            {
                id: "shop_setting_factor_layer",
                title: "لایه های فاکتور",
                route: "/panel/shop_setting/factor_layer",
                params: [":id"],
                view: <TableGrid template="shop_setting_factor_layer" key="shop_setting_factor_layer" />,
                permissionUrl: [
                    "/api/shop/setting/factor_layer/create",
                    "/api/shop/setting/factor_layer/get",
                    "/api/shop/setting/factor_layer/edit",
                    "/api/shop/setting/factor_layer/set",
                    "/api/shop/setting/factor_layer/list",
                    "/api/form/list",
                    "/api/form/list_by_module_id",
                    "/api/financial/system_type/list",
                ]
            },
            {
                id: "userProfile",
                title: "اطلاعات کاربر",
                route: "/panel/userProfile",
                view: <UserProfile template="profile" />,
                permissionUrl: [
                    "/api/user/get_profile"
                ]
            },
            {
                id: "scores",
                title: "امتیازات",
                route: "/panel/scores",
                view: <Score template="score" />,
                permissionUrl: [
                    "/api/score/get_setting",
                    "/api/score/edit_setting"
                ]
            },
            {
                id: "discount_code",
                title: "تخفیف",
                route: "/panel/discount-code",
                params: [":id"],
                view: <TableGrid template="discount_code" key="discount_code" />,
                permissionUrl: [
                    "/api/shop/discount/create",
                    "/api/shop/discount/get",
                    "/api/shop/discount/edit",
                    "/api/shop/discount/set",
                    "/api/shop/discount/list",
                ]
            },
            {
                id: "discount_quantity",
                title: "تخفیف براساس تعداد",
                route: "/panel/discount/quantity",
                params: [":id"],
                view: <TableGrid template="discount_quantity" key="discount_quantity" />,
                permissionUrl: [
                    "/api/shop/discount/quantity/create",
                    "/api/shop/discount/quantity/get",
                    "/api/shop/discount/quantity/edit",
                    "/api/shop/discount/quantity/set",
                    "/api/shop/discount/quantity/list",
                ]
            },
            {
                id: "discount_quantity_range",
                title: "بازه های تخفیف براساس تعداد",
                route: "/panel/discount/quantity_range",
                params: [":id"],
                view: <TableGrid template="discount_quantity_range" key="discount_quantity_range" />,
                permissionUrl: [
                    "/api/shop/discount/quantity/range/create",
                    "/api/shop/discount/quantity/range/get",
                    "/api/shop/discount/quantity/range/edit",
                    "/api/shop/discount/quantity/range/set",
                    "/api/shop/discount/quantity/range/list",
                ],
                show: false,
                force_select: "discount_quantity"
            },
            {
                id: "subscription_type",
                title: "انواع اشتراک",
                route: "/panel/shop_setting/subscription_type",
                params: [":id"],
                view: <TableGrid template="subscription_type" key="subscription_type" />,
                permissionUrl: [
                    "/api/financial/subscription/type/create",
                    "/api/financial/subscription/type/get",
                    "/api/financial/subscription/type/edit",
                    "/api/financial/subscription/type/set",
                    "/api/financial/subscription/type/list",
                ]
            },
        ]
    },

    {
        id: 8,
        title: "ابزارها",
        icon: "fa-screwdriver-wrench",
        childs: [
            {
                id: "form",
                title: "- فرم ساز",
                route: "/panel/tools/form",
                params: [":id"],
                view: <TableGrid template="form" key="form" />,
                permissionUrl: [
                    "/api/form/create",
                    "/api/form/get",
                    "/api/form/edit",
                    "/api/form/set",
                    "/api/form/list",
                    "/api/form/element/create",
                    "/api/form/element/get",
                    "/api/form/element/edit",
                    "/api/form/element/set",
                    "/api/form/element/list",
                ]
            },
            {
                id: "form_element",
                title: "- فیلدها",
                route: "/panel/tools/form/form_element",
                params: [":id"],
                view: <TableGrid template="form_element" key="form_element" />,
                show: false,
                force_select: "form"
            },
            {
                id: "form_enumeration",
                title: "- ثابت ها",
                route: "/panel/form/form_enumeration",
                params: [":id"],
                view: <TableGrid template="form_enumeration" key="form_enumeration" />,
                permissionUrl: [
                    "/api/form/enumeration/create",
                    "/api/form/enumeration/get",
                    "/api/form/enumeration/edit",
                    "/api/form/enumeration/set",
                    "/api/form/enumeration/list",
                ]
            },
            {
                id: "enumeration_location",
                title: "- ثابت های موقعیت جغرافیایی",
                route: "/panel/enumeration_location",
                params: [":id"],
                view: <TableGrid template="enumeration_location" key="enumeration_location" />,
                permissionUrl: [
                    "/api/enumeration/location/create",
                    "/api/enumeration/location/get",
                    "/api/enumeration/location/edit",
                    "/api/enumeration/location/set",
                    "/api/enumeration/location/list",
                ]
            },
            {
                id: "form_validation",
                title: "- اعتبارسنجی ها",
                route: "/panel/form/form_validation",
                params: [":id"],
                view: <TableGrid template="form_validation" key="form_validation" />,
                permissionUrl: [
                    "/api/form/validation/create",
                    "/api/form/validation/get",
                    "/api/form/validation/edit",
                    "/api/form/validation/set",
                    "/api/form/validation/list",
                ]
            },
            {
                id: "page_template",
                title: "- قالب ها",
                route: "/panel/page/page_template",
                params: [":id"],
                view: <TableGrid template="page_template" key="page_template" />,
                permissionUrl: [
                    "/api/page/template/create",
                    "/api/page/template/get",
                    "/api/page/template/edit",
                    "/api/page/template/set",
                    "/api/page/template/list",
                    "/api/page/template/get_code",
                    "/api/page/template/edit_code",
                ]
            },
            {
                id: "page_template_code",
                route: "/panel/page_template/code",
                view: <PageTemplateCode />,
                params: [":id"],
                show: false,
                force_select: "page_template"
            },
            {
                id: "page_function",
                title: "- تابع ها",
                route: "/panel/page/page_function",
                params: [":id"],
                view: <TableGrid template="page_function" key="page_function" />,
                permissionUrl: [
                    "/api/page/function/create",
                    "/api/page/function/get",
                    "/api/page/function/edit",
                    "/api/page/function/set",
                    "/api/page/function/list",
                ]
            },
            // {
            //     id: "shop_setting",
            //     title: "تنظیمات",
            //     route: "/panel/shop_setting",
            //     params: [":id"],
            //     view: <TableGrid template="shop_setting" key="shop_setting" />,
            //     permissionUrl: [
            //         "/api/shop/setting/create",
            //         "/api/shop/setting/get",
            //         "/api/shop/setting/edit",
            //         "/api/shop/setting/set",
            //         "/api/shop/setting/list",
            //     ]
            // },
            {
                id: "serverSettings",
                title: "تنظیمات سرور",
                route: "/panel/server-settings",
                view: <RoutesList template="routes-list/server_settings" key="routes-list/server_settings" />,
                permissionUrl: [
                    "/api/setting/upload/max_size/get",
                    "/api/setting/upload/max_size/edit",
                    "/api/setting/token/get",
                    "/api/setting/token/edit",
                    "/api/setting/template/get",
                    "/api/setting/template/edit",
                    "/api/setting/sms/get",
                    "/api/setting/sms/edit",
                    "/api/setting/sms/expire/get",
                    "/api/setting/sms/expire/edit",
                    "/api/setting/regex/password/get",
                    "/api/setting/regex/password/edit",
                    "/api/setting/recaptcha/get",
                    "/api/setting/recaptcha/edit",
                    "/api/setting/payline/get",
                    "/api/setting/payline/edit",
                    "/api/setting/apps/get",
                    "/api/setting/apps/edit",
                    "/api/setting/proxy/payline/all",
                    "/api/setting/proxy/payline/edit_multi"
                ]
            },
            {
                id: "generalKey",
                title: "کلیدهای عمومی",
                route: "/panel/server-settings/general-key",
                view: <GeneralKey />,
                show: false,
                force_select: "serverSettings"
            },
            {
                id: "cropSizes",
                title: "سایزهای برش تصاویر",
                route: "/panel/server-settings/upload-image-crop-size",
                view: <CropSizes />,
                show: false,
                force_select: "serverSettings"
            },
            {
                id: "thumbnails",
                title: "تامبنیل های تصاویر",
                route: "/panel/server-settings/upload-thumbnails",
                view: <Thumbnails />,
                show: false,
                force_select: "serverSettings"
            },
            {
                id: "demoAudioDuration",
                title: "دمو فایل های صوتی",
                route: "/panel/server-settings/upload-demo-audio-duration",
                view: <DemoAudioDuration />,
                show: false,
                force_select: "serverSettings"
            },
            {
                id: "uploadExtensions",
                title: "انواع فرمت قابل آپلود",
                route: "/panel/server-settings/upload-extensions",
                view: <UploadExtensions />,
                show: false,
                force_select: "serverSettings"
            },
            {
                id: "apps",
                title: "اپلیکیشن ها",
                route: "/panel/server-settings/apps",
                view: <EditSetting template="routes-list/apps" key="routes-list/apps" />,
                show: false,
                force_select: "serverSettings"
            },
            {
                id: "location_service",
                title: "موقعیت جغرافیایی",
                route: "/panel/server-settings/location-service",
                view: <EditSetting template="routes-list/location_service" key="routes-list/location_service" />,
                show: false,
                force_select: "serverSettings"
            },
            {
                id: "user_authorize",
                title: "موقعیت جغرافیایی",
                route: "/panel/server-settings/user-authorize",
                view: <EditSetting template="routes-list/user_authorize" key="routes-list/user_authorize" />,
                show: false,
                force_select: "serverSettings"
            },
            {
                id: "regex_password",
                title: "regex رمزعبور",
                route: "/panel/server-settings/regex-password",
                view: <EditSetting template="routes-list/regex_password" key="routes-list/regex_password" />,
                show: false,
                force_select: "serverSettings"
            },
            {
                id: "sms_expire",
                title: "زمان منقضی شدن پیامک احراز هویت",
                route: "/panel/server-settings/sms-expire",
                view: <EditSetting template="routes-list/sms_expire" key="routes-list/sms_expire" />,
                show: false,
                force_select: "serverSettings"
            },
            {
                id: "sms",
                title: "سرویس پیامک",
                route: "/panel/server-settings/sms",
                view: <EditSetting template="routes-list/sms" key="routes-list/sms" />,
                show: false,
                force_select: "serverSettings"
            },
            {
                id: "notification-setting",
                title: "اطلاع رسانی",
                route: "/panel/server-settings/notification-setting",
                view: <NotificationSetting />,
                show: false,
                force_select: "serverSettings"
            },
            {
                id: "template",
                title: "اطلاعات اپلیکیشن وبسایت",
                route: "/panel/server-settings/template",
                view: <EditSetting template="routes-list/template" key="routes-list/template" />,
                show: false,
                force_select: "serverSettings"
            },
            {
                id: "token",
                title: "توکن",
                route: "/panel/server-settings/token",
                view: <EditSetting template="routes-list/token" key="routes-list/token" />,
                show: false,
                force_select: "serverSettings"
            },
            {
                id: "recaptcha",
                title: "recaptcha",
                route: "/panel/server-settings/recaptcha",
                view: <EditSetting template="routes-list/recaptcha" key="routes-list/recaptcha" />,
                show: false,
                force_select: "serverSettings"
            },
            {
                id: "payline",
                title: "درگاه",
                route: "/panel/server-settings/payline",
                view: <EditSetting template="routes-list/payline" key="routes-list/payline" />,
                show: false,
                force_select: "serverSettings"
            },
            {
                id: "payline-proxy",
                title: "پروکسی درگاه",
                route: "/panel/server-settings/payline-proxy",
                view: <PaylineProxy />,
                show: false,
                force_select: "serverSettings"
            },
            {
                id: "remittance",
                title: "واریز به حساب",
                route: "/panel/server-settings/remittance",
                view: <EditSetting template="routes-list/remittance" key="routes-list/remittance" />,
                show: false,
                force_select: "serverSettings"
            },
            {
                id: "uploadMaxSize",
                title: "حداکثر حجم قابل آپلود",
                route: "/panel/server-settings/upload-max-size",
                view: <EditSetting template="routes-list/upload_max_size" key="routes-list/upload_max_size" />,
                show: false,
                force_select: "serverSettings"
            },
            {
                id: "updateApps",
                title: "بروزرسانی اپلیکیشن ها",
                route: "/panel/server-settings/update-apps",
                view: <UpdateApps />,
                show: false,
                force_select: "serverSettings"
            }
        ]
    },
    {
        id: 9,
        title: "لاگ کاربران",
        icon: "fa-list-check",
        childs: [
            {
                id: "adminLogs",
                title: "لاگ مدیران",
                route: "/panel/adminLogs",
                params: [":id"],
                view: <AdminLogs template="admin_logs" key="admin_logs" />,
                permissionUrl: [
                    "/api/log/user/admin/history",
                    "/api/log/user/admin/get"
                ]
            },
            {
                id: "providerLogs",
                title: "لاگ تامین کنندگان",
                route: "/panel/providerLogs",
                params: [":id"],
                view: <ProviderLogs template="provider_logs" key="provider_logs" />,
                permissionUrl: [
                    "/api/log/user/provider/history",
                    "/api/log/user/provider/get"
                ]
            },
            {
                id: "userLogs",
                title: "لاگ کاربران",
                route: "/panel/userLogs",
                params: [":id"],
                view: <UserLogs template="user_logs" key="user_logs" />,
                permissionUrl: [
                    "/api/log/user/user/history",
                    "/api/log/user/user/get"
                ]
            },
        ]
    },
    {
        id: 10,
        title: "لاگ اپلیکیشن",
        icon: "fa-clipboard-list-check",
        childs: [
            {
                id: "appLogsAdmin",
                title: "لاگ مدیران",
                route: "/panel/appLogs/admin",
                params: [":id"],
                view: <AppLogs template="app_admin_logs" key="app_admin_logs" />,
                permissionUrl: [
                    "/api/log/application/admin/history",
                    "/api/log/application/admin/get",
                ]
            },
            {
                id: "appLogsProvider",
                title: "لاگ تامین کنندگان",
                route: "/panel/appLogs/provider",
                params: [":id"],
                view: <AppLogs template="app_provider_logs" key="app_provider_logs" />,
                permissionUrl: [
                    "/api/log/application/provider/history",
                    "/api/log/application/provider/get",
                ]
            },
            {
                id: "appLogsWebsite",
                title: "لاگ وب سایت",
                route: "/panel/appLogs/website",
                params: [":id"],
                view: <AppLogs template="app_website_logs" key="app_website_logs" />,
                permissionUrl: [
                    "/api/log/application/website/history",
                    "/api/log/application/website/get",
                ]
            },
            {
                id: "appLogsAPI",
                title: "لاگ API",
                route: "/panel/appLogs/api",
                params: [":id"],
                view: <AppLogs template="app_api_logs" key="app_api_logs" />,
                permissionUrl: [
                    "/api/log/application/api/history",
                    "/api/log/application/api/get",
                ]
            },
            {
                id: "appCommits",
                title: "لاگ تغییرات کد",
                route: "/panel/appLogs/commits",
                params: [":id"],
                view: <AppLogs template="app_commits_logs" key="app_commits_logs" />,
                permissionUrl: [
                    "/api/log/application/api/history",
                    "/api/log/application/api/get",
                ]
            },
            {
                id: "appLogsJob",
                title: "لاگ Job",
                route: "/panel/appLogs/job",
                params: [":id"],
                view: <AppLogs template="app_job_logs" key="app_job_logs" />,
                permissionUrl: [
                    "/api/log/application/job/history",
                    "/api/log/application/job/get",
                ]
            },
        ]
    },
    {
        id: 11,
        title: "تنظیمات رمزعبور",
        route: "/panel/password-setting",
        icon: "",
        view: <PasswordSetting />,
        show: false
    }
];

export default routes;