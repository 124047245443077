import { useContext, useId } from "react";
import { useForm } from "react-hook-form";
import ReactTextareaAutosize from "react-textarea-autosize";
import { faNumToEng, numberRegex } from "utils";
import ErrorMessage from "components/form-generator/error-msg";
import { ReducerContext } from "../reducerProvider";

const DetailForm = (props) => {
    const { doneCallback } = props;
    const { state, dispatch } = useContext(ReducerContext);

    const formId = useId();
    const pointInfo = state.pointInfo.data;
    const defaultValue = state.defaultValue;

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm ({
        mode: "onChange"
    });

    const submitHandler = async (data) => {
        dispatch({ type: "CLOSE_MODALS" });

        const payload = {
            unit: parseInt(data.unit),
            number: parseInt(data.number),
            address: data.address,
            coordinates: state.centerPoint,
            postal_code: faNumToEng(data.postalCode),
            meta_data: pointInfo.data
        }

        doneCallback(payload);
    }

    return (
        <>
            <button
                className="bg-red-500 hover:bg-red-600 transition-colors text-white text-xs rounded-md py-1 px-2 flex items-center mb-6"
                onClick={() => {
                    dispatch({ type: "SET_SHOW_DETAIL_FORM", payload: false });
                }}
            >
                <i className="fa-regular fa-angle-right ml-1" />
                بازگشت
            </button>

            <form className="px-2">
                <div className="grid grid-cols-2 gap-4">
                    <div className="field-c1 col-span-2">
                        <label htmlFor={`${formId}-address`} className="label">آدرس دقیق *</label>
                        <ReactTextareaAutosize
                            minRows={3}
                            id={`${formId}-address`}
                            placeholder="وارد نمایید"
                            autoComplete="off"
                            {...register("address", { required: true })}
                            defaultValue={pointInfo.data.formatted_address}
                            className="resize-none"
                        />

                        {!!errors.address && <span className="error-msg">{ErrorMessage(errors.address.type)}</span>}
                    </div>

                    <div className="field-c1">
                        <label htmlFor={`${formId}-number`} className="label">پلاک</label>
                        <input
                            type="number"
                            id={`${formId}-number`}
                            placeholder="مثال: 11"
                            autoComplete="off"
                            {...register("number", { required: false, pattern: numberRegex })}
                            defaultValue={defaultValue.number || ""}
                        />

                        {!!errors.number && <span className="error-msg">{ErrorMessage(errors.number.type)}</span>}
                    </div>

                    <div className="field-c1">
                        <label htmlFor={`${formId}-unit`} className="label">واحد</label>
                        <input
                            type="number"
                            id={`${formId}-unit`}
                            placeholder="مثال: 4"
                            autoComplete="off"
                            {...register("unit", { required: false, pattern: numberRegex })}
                            defaultValue={defaultValue.unit || ""}
                        />

                        {!!errors.unit && <span className="error-msg">{ErrorMessage(errors.unit.type)}</span>}
                    </div>

                    <div className="field-c1 col-span-2">
                        <label htmlFor={`${formId}-postalCode`} className="label">کد پستی</label>
                        <input
                            type="tel"
                            maxLength={10}
                            id={`${formId}-postalCode`}
                            className="dir-ltr"
                            placeholder="1234567890"
                            autoComplete="off"
                            {...register("postalCode", { pattern: numberRegex, minLength: 10, maxLength: 10 })}
                            defaultValue={defaultValue.postal_code || ""}
                        />

                        {!!errors.postalCode && <span className="error-msg">{ErrorMessage(errors.postalCode.type)}</span>}
                    </div>

                    <div className="col-span-2">
                        <button
                            className="w-full bg-primary-100 hover:bg-primary-100-dark text-white font-bold rounded-md py-4 text-sm transition-colors relative overflow-hidden"
                            type="button"
                            onClick={handleSubmit(submitHandler)}
                        >
                            ثبت آدرس
                        </button>
                    </div>
                </div>
            </form>
        </>
    )
}

export default DetailForm;