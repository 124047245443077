import { useEffect, useState } from "react";
import { createPortal } from "react-dom";

const ExtraBoxPortal = props => {
    const { children, selector } = props;
    const [mounted, setMounted] = useState(false);

    useEffect(() => {
        setMounted(true);
        return () => setMounted(false);
    }, [])

    return mounted ? createPortal(children, document.querySelector(selector)) : null;
}

export default ExtraBoxPortal