import { memo, useEffect, useState } from "react";
import style from "../../password-setting.module.scss";
import { faNumToEng, secondToMin } from "utils";
import { toast } from "react-toastify";
import MasterAPI from "service/master";
import { CSSTransition } from "react-transition-group";

const Input = props => {
    const {
        mode,
        value,
        changeHandler
    } = props;

    const [timer, setTimer] = useState(60);
    const [resendLoading, setResendLoading] = useState(false);

    useEffect(() => {
        const timerTimeout = setTimeout(() => {
            if (timer > 0)
                setTimer(prev => prev - 1);
        }, 1000)

        return () => clearTimeout(timerTimeout);
    }, [timer])

    const localChangeHandler = e => {
        const val = faNumToEng(e.target.value);

        if (!isNaN(val))
            changeHandler(val);
    }

    const resendHandler = async () => {
        if (resendLoading || timer !== 0)
            return;

        setResendLoading(true);
        try {
            const res = await MasterAPI({
                url: "/api/user/change_two_factor_resend_code",
                method: "PUT",
                payloadType: 3,
                callback: () => setResendLoading(false),
                data: {
                    method_id: mode
                }
            });

            toast.success(res.message);
            setTimer(60);
        } catch (e) {
            toast.error(e.message);
        }
    }

    return (
        <div className="flex items-stretch">
            <div className={`text-field grow ${value?.length > 0 ? "has-val" : ""}`}>
                <label htmlFor="verify-code">کد ارسال شده به {mode === 1 ? "شماره همراه" : "ایمیل"}</label>

                <input
                    type="text"
                    placeholder="وارد نمایید ..."
                    onChange={localChangeHandler}
                    value={value}
                    id="verify-code"
                />
            </div>

            <button
                type="button"
                className={`bg-gray-100 dark:bg-slate-800 text-gray-700 dark:text-slate-200 mr-3 ${style.resend} ${timer === 0 ? style.enable : ""}`}
                onClick={resendHandler}
            >
                ارسال مجدد

                {
                    timer > 0 && <span>( {secondToMin(timer)} )</span>
                }

                <CSSTransition in={resendLoading} timeout={300} unmountOnExit={true}>
                    <span className={`anim-c1 fit-center pos-cover ${style.loadingC2}`}>
                        <i className="fa-solid fa-spinner spin"></i>
                    </span>
                </CSSTransition>
            </button>
        </div>
    )
}

export default memo(Input);