import axios from 'axios';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { SarirEditor } from "sarir-editor";
import "sarir-editor/css";
import { SERVICE_1 } from 'service/routes';
import { getProductImage } from 'utils';

function Editor(props) {
    const {
        changeHandler,
        value,
        label
    } = props;

    const accessToken = useSelector(state => state.main.accessToken);

    const uploadAdapter = useCallback((loader, signal) => {
        return new Promise(async (resolve) => {
            try {
                const file = await loader.file;

                const formData = new FormData();
                formData.append("folder", "public");
                formData.append("files", file);

                const uploadRes = await axios({
                    url: `${SERVICE_1}/api/upload/multi`,
                    method: "POST",
                    data: formData,
                    signal,
                    onUploadProgress: progressEvent => {
                        loader.uploadTotal = progressEvent.total;
                        loader.uploaded = progressEvent.loaded;
                    },
                    headers: {
                        Authorization: accessToken
                    }
                })

                resolve({
                    default: getProductImage(uploadRes.data.file[0].original)
                })
            } catch {

            }
        })
    }, [accessToken])

    return (
        <>
            <label className='title-c2 tp-27 mb-1 block'>{label}</label>

            <div className='dir-ltr in'>
                <SarirEditor
                    changeHandler={e => changeHandler(e)}
                    defaultValue={value}
                    uploadAdaptor={uploadAdapter}
                />
            </div>
        </>
    );
}

export default Editor;