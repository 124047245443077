import InputText from "components/shared/inputs/input-text";
import Breadcrumb from "components/shared/breadcrumb";
import SwitchButton from "components/shared/switchButton";
import UseFetch from "hooks/useFetch";
import { memo, useEffect, useState } from "react";
import Skeleton from "./skeleton";
import { faNumToEng, numberRegex } from "utils";
import { CSSTransition } from "react-transition-group";
import { toast } from "react-toastify";
import MasterAPI from "service/master";
import SectionTitle from "components/form-generator/fields/section-title";
import Checkbox from "components/form-generator/fields/checkbox";
import MessagePattern from "./messagePattern";

const NotificationSetting = () => {
    const { isLoading, error, data } = UseFetch("/api/setting/notification/get");

    const [notification, setNotification] = useState({});
    const [localLoading, setLocalLoading] = useState(true);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [submitEnabled, setSubmitEnabled] = useState(false);

    useEffect(() => {
        if (!isLoading && !error) {
            setNotification(data.data || []);
            setLocalLoading(false);
        }
    }, [isLoading, error, data])

    const changeHandler = (data, key) => {
        console.log("they calling me key= ", key, " data= ", data);

        setNotification(prev => {
            const newState = { ...prev };
            if (typeof data === 'object') {
                newState[key] = { ...newState[key], ...data };
                console.log("set object: ", newState[key]);
                console.log("notification: ", notification);

            } else {
                newState[key] = data;
            }
            return newState;
        });

        setSubmitEnabled(true);
    }

    const submitHandler = async () => {
        if (submitLoading || error || localLoading || !submitEnabled)
            return;

        setSubmitLoading(true);

        try {
            const res = await MasterAPI({
                url: "/api/setting/notification/edit",
                data: notification,
                method: "PUT",
                callback: () => setSubmitLoading(false),
                payloadType: 3
            });

            toast.success(res.message);
            setSubmitEnabled(false);
        } catch (e) {
            toast.error(e.message);
        }
    }

    return (
        <div className="m-container">
            <div className="flex item-start md:items-center justify-start md:justify-between flex-col md:flex-row mb-6 options-bar">
                <div className="mb-6 md:mb-0">
                    <h1 className="title-c1 tp-21">اطلاع رسانی</h1>

                    <Breadcrumb data={[
                        { title: "تنظیمات فروشگاه" },
                        { title: "تنظیمات سرور", link: "/panel/server-settings" },
                        { title: "اطلاع رسانی" }
                    ]} />
                </div>

                <div className="flex">
                    <button
                        className={`bg-primary-100 px-3 transition-all text-white rounded-md fit-center text-sm h-10 mr-3 relative overflow-hidden ${!submitEnabled ? "opacity-50 cursor-default" : "hover:bg-primary-200"}`}
                        onClick={submitHandler}
                    >
                        <i className="fa-light fa-turn-down-left ml-2"></i>
                        ثبت تغییرات

                        <CSSTransition unmountOnExit={true} in={submitLoading} timeout={200}>
                            <div className="pos-cover anim-c4 fit-center text-white bg-primary-200">
                                <i className="fa-solid fa-spinner spin"></i>
                            </div>
                        </CSSTransition>
                    </button>
                </div>

            </div>

            {
                !localLoading ? (
                    !error ? (
                        <div className="wrap-box tp-22 page-transition">
                            <div className="py-2">
                                <div className="grid gap-3">
                                    <div className="col-span-12">
                                        <div className="grid grid-cols-2 gap-4 form-base">

                                            <div className="field col-span-2">
                                                <SectionTitle
                                                    className="field col-span-2"
                                                    title="تیکت کاربر"
                                                />
                                            </div>

                                            <div className="field col-span-2">
                                                <Checkbox
                                                    changeHandler={e => changeHandler(e, "user_ticket_new_messge_send_message")}
                                                    label="پاسخ تیکت - ارسال اعلان"
                                                    value={notification.user_ticket_new_messge_send_message}
                                                />
                                            </div>

                                            <div className="field col-span-2">
                                                <Checkbox
                                                    changeHandler={e => changeHandler(e, "user_ticket_new_message_send_sms")}
                                                    label="پاسخ تیکت - ارسال پیامک"
                                                    value={notification.user_ticket_new_message_send_sms}
                                                />
                                            </div>

                                            <div className="field col-span-2">
                                                <Checkbox
                                                    changeHandler={e => changeHandler(e, "user_ticket_change_status_send_message")}
                                                    label="وضعیت تیکت - ارسال اعلان"
                                                    value={notification.user_ticket_change_status_send_message}
                                                />
                                            </div>

                                            <div className="field col-span-2">
                                                <Checkbox
                                                    changeHandler={e => changeHandler(e, "user_ticket_change_status_send_sms")}
                                                    label="وضعیت تیکت - ارسال پیامک"
                                                    value={notification.user_ticket_change_status_send_sms}
                                                />
                                            </div>

                                            <div className="field col-span-2">
                                                <SectionTitle
                                                    className="field col-span-2"
                                                    title="نظرات کاربر"
                                                />
                                            </div>

                                            <div className="field col-span-2">
                                                <Checkbox
                                                    changeHandler={e => changeHandler(e, "user_comment_change_status_send_message")}
                                                    label="وضعیت نظر - ارسال اعلان"
                                                    value={notification.user_comment_change_status_send_message}
                                                />
                                            </div>

                                            <div className="field col-span-2">
                                                <Checkbox
                                                    changeHandler={e => changeHandler(e, "user_comment_change_status_send_sms")}
                                                    label="وضعیت نظر - ارسال پیامک"
                                                    value={notification.user_comment_change_status_send_sms}
                                                />
                                            </div>

                                            <div className="field col-span-2">
                                                <Checkbox
                                                    changeHandler={e => changeHandler(e, "user_comment_new_reply_send_message")}
                                                    label="پاسخ نظر - ارسال اعلان"
                                                    value={notification.user_comment_new_reply_send_message}
                                                />
                                            </div>

                                            <div className="field col-span-2">
                                                <Checkbox
                                                    changeHandler={e => changeHandler(e, "user_comment_new_reply_send_sms")}
                                                    label="پاسخ نظر - ارسال پیامک"
                                                    value={notification.user_comment_new_reply_send_sms}
                                                />
                                            </div>


                                            <div className="field col-span-2">
                                                <SectionTitle
                                                    className="field col-span-2"
                                                    title="تیکت تامین کننده"
                                                />
                                            </div>

                                            <div className="field col-span-2">
                                                <Checkbox
                                                    changeHandler={e => changeHandler(e, "provider_ticket_new_messge_send_message")}
                                                    label="پاسخ تیکت - ارسال اعلان"
                                                    value={notification.provider_ticket_new_messge_send_message}
                                                />
                                            </div>

                                            <div className="field col-span-2">
                                                <Checkbox
                                                    changeHandler={e => changeHandler(e, "provider_ticket_new_message_send_sms")}
                                                    label="پاسخ تیکت - ارسال پیامک"
                                                    value={notification.provider_ticket_new_message_send_sms}
                                                />
                                            </div>

                                            <div className="field col-span-2">
                                                <Checkbox
                                                    changeHandler={e => changeHandler(e, "provider_ticket_change_status_send_message")}
                                                    label="وضعیت تیکت - ارسال اعلان"
                                                    value={notification.provider_ticket_change_status_send_message}
                                                />
                                            </div>

                                            <div className="field col-span-2">
                                                <Checkbox
                                                    changeHandler={e => changeHandler(e, "provider_ticket_change_status_send_sms")}
                                                    label="وضعیت تیکت - ارسال پیامک"
                                                    value={notification.provider_ticket_change_status_send_sms}
                                                />
                                            </div>

                                            <div className="field col-span-2">
                                                <SectionTitle
                                                    className="field col-span-2"
                                                    title="کد ثبت نام"
                                                />
                                            </div>
                                            <div className="field col-span-2">
                                                <MessagePattern
                                                    changeHandler={changeHandler}
                                                    value={notification.signin_auth_code}
                                                    id="signin_auth_code"
                                                />
                                            </div>

                                            <div className="field col-span-2">
                                                <SectionTitle
                                                    className="field col-span-2"
                                                    title="کد ورود"
                                                />
                                            </div>
                                            <div className="field col-span-2">
                                                <MessagePattern
                                                    changeHandler={changeHandler}
                                                    value={notification.auth_code}
                                                    id="auth_code"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="wrap-box tp-22 page-transition">
                            <p className="text-sm text-red-600 dark:text-red-400">خطا در دریافت اطلاعات !</p>
                        </div>
                    )
                ) : <Skeleton />
            }
        </div >
    )
}

export default memo(NotificationSetting);